<template>
  <div class="px-3 mt-2 customize-bg">
    <template v-for="(tOption, index) in pageOptions" :key="tOption.abbr">
      <div class="d-flex justify-content-between align-items-center flex-wrap" :class="{ 'mt-3': index !== 0 }">
        <div class="sub-title pt-2" :class="{ 'bold-sub-title': isBold }">
          {{ tOption.name }}
        </div>
        <div />
        <div class="w-50">
          <color-circle-box
            :value="getSelectedColor(tOption.abbr)"
            :is-background="true"
            @changed="(color) => colorChanged(color, tOption.abbr)"
            @select="actionClicked"
          />
        </div>
      </div>
      <hr v-if="index < pageOptions.length - 1" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import ColorCircleBox from '@/shared/components/color-customize-box.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
const { currentTemplate, changeThemeBackgroundsKey } = characterStylizeStore();

const emits = defineEmits(['click']);
const props = defineProps({
  pageOptions: {
    type: Array,
    default: () => [
      { abbr: 'page', name: 'Entire Page' },
      { abbr: 'topSection', name: 'Top Section' },
      { abbr: 'buttons', name: 'Buttons' },
      { abbr: 'infoSection', name: 'Info Sections' },
    ],
  },
  fontSize: {
    type: String,
    default: '22px',
  },
  isBold: {
    type: Boolean,
    default: false,
  },
});

const pageOptions = toRef(props, 'pageOptions') as any;

const getSelectedColor = (abbr: string) => {
  return get(currentTemplate.value, `data.theme.backgrounds.${abbr}`);
};

const colorChanged = (newColor: string, abbr: any) => {
  changeThemeBackgroundsKey(abbr, newColor);
};

const actionClicked = (select: any) => {
  emits('click', select);
};
</script>

<style lang="sass" scoped>
.bold-sub-title
  font-weight: bold

.customize-bg
  border-radius: 12px
  padding-top: 1rem
.sub-title
  color: #214163
  font-size: v-bind(fontSize)
  margin-top: -15px
  @media(max-width: 365px)
    font-size: 18px

.dark .sub-title
  color: #000000 !important
</style>
